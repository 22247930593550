import React from 'react'
import styled from 'styled-components'

const height = 400
const MapContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: ${height}px;
  position: relative;
  resize: none;
  max-width: 100%;

  & iframe {
    width: 100%;
    height: 100%;
    border: none;
    object-fit: cover;
    object-position: 50% 50%;
  }
`

const GoogleMap = ({ id }) => {
  return (
    <MapContainer id={id}>
      <iframe
        allow="fullscreen"
        allowFullScreen
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.703429791039!2d22.00952550533492!3d50.03565274363269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfafe829184b7%3A0xde781442c9fbca27!2sFryderyka%20Szopena%207%2C%2035-055%20Rzesz%C3%B3w!5e0!3m2!1sen!2spl!4v1616243892337!5m2!1sen!2spl"
        title="Psycholog, Psychoterapeuta Agnieszka Wojnar-Jadczyszyn - Prywatny Gabinet Psychoterapii"
        loading="lazy"
        height={height}
      />
    </MapContainer>
  )
}

export default GoogleMap
